<template>
  <v-container>
    <v-card>
      <v-card-title>Send Notification</v-card-title>

      <v-card-text>
        <v-form @submit.prevent="sendNotification">
          <v-text-field v-model="title" label="Title"></v-text-field>

          <v-textarea
            v-model="message"
            label="Message"
            :counter="messageCounter"
            :rules="[(v) => v.length <= 1000 || 'Message is too long']"
          ></v-textarea>

          <v-checkbox v-model="forDriver" label="For Driver" disabled readonly></v-checkbox>
          <v-checkbox v-model="forCustomer" label="For Customer" disabled readonly></v-checkbox>

          <v-select v-model="selectedItem" :items="dropdownItems" label="Select Option" disabled readonly></v-select>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn :disabled="loading || !title || !message" @click="sendNotification" :loading="loading" color="primary">
          Send
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="snackbar" :color="snackbarColor" timeout="5000" class="custom-snackbar">
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { sendNotification } from "@/services/admin";

export default {
  data() {
    return {
      title: "",
      message: "",
      messageCounter: 1000,
      forDriver: true,
      forCustomer: false,
      selectedItem: "Request List Screen",
      dropdownItems: ["Request List Screen"],
      loading: false,
      snackbar: false,
      snackbarColor: "success",
      snackbarText: "",
    };
  },
  methods: {
    async sendNotification() {
      try {
        this.loading = true;

        const payload = {
          title: this.title,
          message: this.message,
          is_for_driver: this.forDriver,
          is_for_customer: this.forCustomer,
          target_screen: this.selectedItem,
        };

        await sendNotification(payload);

        this.snackbarColor = "success";
        this.snackbarText = "Notification sent successfully";
        this.snackbar = true;

        this.title = "";
        this.message = "";
      } catch (error) {
        console.error("Error sending notification:", error);
        this.snackbarColor = "error";
        this.snackbarText = `Error sending notification: ${error.message || "Unknown error"}`;
        this.snackbar = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>


<style scoped>
.custom-snackbar {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  max-width: 500px;
  font-size: 200rem;
  padding: 20px;
}
</style>